import { MatDialogConfig } from '@angular/material/dialog';
import { CoreButtonState } from 'aimmo-core2/app/components/core-button/core-button.model';

export interface CoreConfirmDialogParam {
  type: CoreConfirmDialogType;
  title?: string;
  message?: string;
  positiveButtonText: string;
  negativeButtonText?: string;
  positiveButtonState?: CoreButtonState;
  hideCloseButton?: boolean;
}

export enum CoreConfirmDialogType {
  alert = 'alert',
  confirm = 'confirm',
  confirmOnlyTitle = 'confirm-only-title'
}

function makeDialogConfig(
  type: CoreConfirmDialogType,
  title: string | undefined,
  message: string | undefined,
  positiveButtonText: string,
  negativeButtonText?: string,
  positiveButtonState?: CoreButtonState,
  hideCloseButton?: boolean
): MatDialogConfig<CoreConfirmDialogParam> {
  return {
    panelClass: 'aimmo-core-dialog',
    minWidth: '432px',
    maxWidth: '640px',
    data: { type, title, message, positiveButtonText, negativeButtonText, positiveButtonState, hideCloseButton }
  };
}

export function makeCoreConfirmDialogConfig(
  title: string, message: string, positiveButtonText: string, negativeButtonText?: string, positiveButtonState?: CoreButtonState): MatDialogConfig<CoreConfirmDialogParam> {
  return makeDialogConfig(CoreConfirmDialogType.confirm, title, message, positiveButtonText, negativeButtonText, positiveButtonState);
}

export function makeCoreOnlyTitleConfirmDialogConfig(
  title: string, positiveButtonText: string, negativeButtonText?: string, positiveButtonState?: CoreButtonState): MatDialogConfig<CoreConfirmDialogParam> {
  return {
    ...makeDialogConfig(CoreConfirmDialogType.confirmOnlyTitle, title, undefined, positiveButtonText, negativeButtonText, positiveButtonState, true),
  };
}
