import { AimDialogModule } from '@aimmo/design-system/aim-dialog/src';
import { SafePipe } from '@aimmo/pipes/safe';
import { Component, Inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreButtonComponent } from 'aimmo-core2/app/components/core-button/core-button.component';
import { CoreButtonState, CoreButtonTheme } from 'aimmo-core2/app/components/core-button/core-button.model';
import {
  CoreConfirmDialogParam,
  CoreConfirmDialogType
} from 'aimmo-core2/app/shared/dialogs/core-confirm-dialog/core-confirm-dialog.model';

@Component({
  selector: 'app-core-confirm-dialog',
  templateUrl: './core-confirm-dialog.component.html',
  styleUrls: ['./core-confirm-dialog.component.scss'],
  standalone: true,
  imports: [
    AimDialogModule,
    SafePipe,
    CoreButtonComponent
  ]
})
export class CoreConfirmDialogComponent {
  public title = signal<string>('');
  public message = signal<string>('');
  public positiveButtonText = signal<string>('');
  public negativeButtonText = signal<string>('');
  public positiveButtonState = signal<CoreButtonState>(CoreButtonState.normal);
  public hideCloseButton = signal<boolean>(false);
  public isConfirmOnlyTitleType = signal<boolean>(false);
  public readonly CoreButtonTheme = CoreButtonTheme;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: CoreConfirmDialogParam,
    private dialogRef: MatDialogRef<CoreConfirmDialogComponent, boolean>
  ) {
    this.title.set(this.data.title);
    this.message.set(this.data.message);
    this.positiveButtonText.set(this.data.positiveButtonText);
    this.negativeButtonText.set(this.data.negativeButtonText);
    this.positiveButtonState.set(this.data.positiveButtonState);
    this.hideCloseButton.set(this.data.hideCloseButton);
    this.isConfirmOnlyTitleType.set(this.data.type === CoreConfirmDialogType.confirmOnlyTitle);
  }

  public onConfirm(): void {
    this.dialogRef.close(true);
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }
}
