<aim-dialog-header [hideCloseButton]="isConfirmOnlyTitleType()" [useNormalFontWeight]="isConfirmOnlyTitleType()">
  <span class="title" [innerHTML]="title()|safe:'html'"></span>
</aim-dialog-header>

@if (!!message()) {
  <aim-dialog-content>
    <p class="message" [innerHTML]="message()|safe:'html'"></p>
  </aim-dialog-content>
}

<aim-dialog-actions>
  <aim-core-button [theme]="CoreButtonTheme.ghost" (click)="onCancel()">{{ negativeButtonText() }}</aim-core-button>
  <aim-core-button [state]="positiveButtonState()" (click)="onConfirm()">{{ positiveButtonText() }}</aim-core-button>
</aim-dialog-actions>
